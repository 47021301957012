// import { vlogger, datadogRumInit } from './UtilsTools';

const url = window.location.pathname;

if (
  !(url.includes('tools') || url.includes('questionSetEdit')) ||
  url === '/' ||
  (url === '/tools' && window.location.hash === '')
) {
  var base_url = window.location.origin;
  var url_to_redirect = base_url + '/tools' + (window.location.hash || '#/welcome');
  window.location.href = url_to_redirect;
}

(function () {
  function showTopLoading() {
    window.$('#loading').show();
  }
  function hideTopLoading() {
    window.$('#loading').hide();
  }

  window.showTopLoading = showTopLoading;
  window.hideTopLoading = hideTopLoading;
})();

function loadCommonNavbar() {
  // document.addEventListener(
  //   'DOMContentLoaded',
  //   function () {
      document.getElementById('toolsCommonNavbar').innerHTML = `<div class="pure-g header" style="padding: 1px 0 1px">
        <div class="pure-u-16-24">
            <h1 style="color:white">
                <a ui-sref="welcome" ng-click="onNonReactLinkClick($event)">Vedantu - Tools</a>
                <span style="font-size: 13px;vertical-align: text-top;padding-left: 5px;">
                <strong ng-if="$root.myAdminCredential==='SUPER_ADMIN'">Super</strong> Admin*
                </span>
            </h1>
        </div>
        <div class="pure-u-4-24" ng-controller="RedirectBetaCtrl" ng-if="showGoToOld" id="goToOldButton">
            <button class="pure-button" ng-click="redirectToOriginal()">Go To Old</button>
        </div>
        <div class="pure-u-4-24" ng-controller="LogoutCtrl">
            <button class="pure-button" ng-click="logout()">Logout</button>
        </div>
      </div>`;
  //   },
  //   false
  // );
}

if (url.includes('/questionSetEdit')) {
  try {
    import('VedantuToolsReact/MainContainer').then(() => {});
  } catch (e) {
    console.error('Tools Question Set Load Error', e);
  }
} else {
  try {
    loadCommonNavbar();

    import('VedantuToolsAngular/MainContainer').then(() => {});

    import('VedantuToolsReact/MainContainer').then(() => {});
  } catch (e) {
    console.error('Tools Main Load Error', e);
  }
}

if (url.includes('/questionSetEdit')) {
  console.log('Tools: ', 'DASHBOARD EXECUTED FOR QUESTION SET SERVICE');
} else {
  console.log('Tools: ', 'DASHBOARD EXECUTED');
}

// setTimeout(() => {
// try {
//   vlogger.setProd();
//   vlogger.init();
//   vlogger.setGlobalContext({ appName: 'vtoolsapp' });
// } catch (e) {
//   console.warn('vlogger init failed', e);
// }
// try {
//   datadogRumInit();
// } catch (e) {
//   console.warn('datadog init failed', e);
// }
// }, 0);
